import CryptoJS from 'crypto-js';

const secretKey = 'iniSecretKeyAnda'; // Ganti dengan secret key yang aman

class TokenService {
  
  encryptData(data) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext;
  }

  decryptData(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    const originalData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalData);
  }

  getLocalRefreshToken() {
    const encryptedUser = localStorage.getItem("OAT");
    if (encryptedUser) { 
      return this.decryptData(encryptedUser)?.refreshToken;
    }
    return null;
  }

  getLocalAccessToken() {
    const encryptedUser = localStorage.getItem("OAT");
    if (encryptedUser) { 
      return this.decryptData(encryptedUser)?.accessToken;
    }
    return null;
  }

  updateLocalAccessToken(token) {
    const encryptedUser = localStorage.getItem("OAT");
    if (encryptedUser) {
      let user = this.decryptData(encryptedUser);
      user.accessToken = token; 
      const encryptedData = this.encryptData(user);
      localStorage.setItem("OAT", encryptedData);
    }
  }

  getUser() {
    const encryptedUser = localStorage.getItem("OAT");
    if (encryptedUser) { 
      return this.decryptData(encryptedUser);
    }
    return null;
  }

  setUser(user) { 
    const encryptedData = this.encryptData(user);
    localStorage.setItem("OAT", encryptedData);
  }

  removeUser() {
    localStorage.removeItem("OAT");
  }
}

export default new TokenService();
